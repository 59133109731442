import React, { useState, useRef, useEffect } from "react";
import { Input, Button, Toast, Image, Cascader, Radio } from "antd-mobile";
import axios from "../../util/axios";
import { CloseCircleFill, RightOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import _ from "lodash";
function AddServiceProvider() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [area, setArea] = useState([]);
  const [areaVal, setAreaVal] = useState([]);
  let [state, setState] = useState({
    name: "",
    mobile: "",
    company_name: "",
    business_license: "",
    commission_ratio: "",
    total_qrcode: "",
    province_id: "",
    city_id: "",
    area_id: "",
    grade: 2,
  });
  let business_license_ref = useRef();
  let getArea = (pid, is_children = 1) => {
    return new Promise((resolve) => {
      axios.post("/Staff/Area/list", pid ? { pid: pid } : null).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].label = list[i].name;
            list[i].value = list[i].name;
            if (is_children) list[i].children = [];
          }
          if (list.length) resolve(list);
        }
      });
    });
  };
  let fetchOptionsForValue = async (v, level1) => {
    let level = level1 - 1;
    let _area = _.cloneDeep(area);
    // 省代
    if (state.grade == 3) {
      _area.map((item) => {
        delete item.children;
        setArea(_area);
      });
    }
    // 市代
    if (state.grade == 1) {
      _area.map((item) => {
        if (level == 2) {
          if (item.name == v && !item.children.length) {
            getArea(item.id, 0).then((res) => {
              item.children = res;
              setArea(_area);
            });
          }
        }
      });
    }
    // 区代
    if (state.grade == 2) {
      console.log(v, level);
      _area.map((item) => {
        if (level == 2) {
          if (item.name == v && !item.children.length) {
            getArea(item.id).then((res) => {
              item.children = res;
              setArea(_area);
            });
          }
        }
        if (level == 0 && item.children) {
          item.children.map((row) => {
            if (row.name == v && !row.children.length) {
              getArea(row.id, 0).then((res) => {
                row.children = res;
                setArea(_area);
              });
            }
          });
        }
      });
    }
  };
  let changeGrade = (e) => {
    getArea().then((res) => {
      setArea(res);
      setAreaVal([]);
      setState({
        ...state,
        grade: e,
        province_id: "",
        city_id: "",
        area_id: "",
      });
    });
  };
  let confirm = (value) => {
    if (state.grade == 3) {
      let province = area.filter((item) => item.name == value[0]);
      setAreaVal(value);
      setVisible(false);
      setState({
        ...state,
        province_id: province[0].id,
        city_id: 0,
      });
    }
    if (state.grade == 1) {
      if (value.length > 1) {
        let province = area.filter((item) => item.name == value[0]);
        let city = province[0].children.filter((item) => item.name == value[1]);
        setAreaVal(value);
        setVisible(false);
        setState({
          ...state,
          province_id: province[0].id,
          city_id: city[0].id,
        });
      }
    }
    if (state.grade == 2) {
      if (value.length > 2) {
        let province = area.filter((item) => item.name == value[0]);
        let city = province[0].children.filter((item) => item.name == value[1]);
        let _area = city[0].children.filter((item) => item.name == value[2]);
        setAreaVal(value);
        setVisible(false);
        console.log(areaVal);
        setState({
          ...state,
          province_id: province[0].id,
          city_id: city[0].id,
          area_id: _area[0].id,
        });
      }
    }
  };
  let submit = () => {
    try {
      if (!state.name) throw "请输入合作商姓名";
      if (!state.mobile) throw "请输入合作商手机号";
      if (!state.company_name) throw "请输入公司名称";
      if (!state.province_id) throw "请选择所属区域";
      if (!state.business_license) throw "请上传营业执照";
      if (!state.commission_ratio) throw "请输入佣金比例";
      if (!state.total_qrcode) throw "请输入数量";
      axios.post("/Staff/Facilitator/add", state).then((res) => {
        if (res.code == 0) {
          navigate("/scanCode", { state: res.result.qrcode });
        } else {
          Toast.show({
            content: res.msg,
          });
        }
      });
    } catch (e) {
      Toast.show({
        content: e,
      });
    }
  };
  let upFile = () => {
    let formData = new FormData();
    formData.append("file", business_license_ref.current.files[0]);
    Toast.show({
      duration: 0,
      icon: "loading",
      content: "上传中...",
    });
    axios.uploadFile("/Staff/Upload/index", formData).then((res) => {
      Toast.clear();
      if (res.code === 0) {
        setState({
          ...state,
          business_license: res.result.path,
        });
      } else {
        Toast.show({
          content: res.msg,
        });
      }
    });
  };
  useEffect(() => {
    getArea().then((res) => {
      setArea(res);
    });
    document.title = "添加合作商进件";
  }, []);
  return (
    <>
      <div className="service-provider">
        <div className="form-item">
          <div className="title">合作商姓名</div>
          <Input
            value={state.name}
            onChange={(e) => setState({ ...state, name: e })}
            placeholder="请输入合作商姓名"
          />
        </div>
        <div className="form-item">
          <div className="title">合作商手机号</div>
          <Input
            value={state.mobile}
            onChange={(e) => setState({ ...state, mobile: e })}
            placeholder="请输入合作商手机号"
          />
        </div>
        <div className="form-item">
          <div className="title">公司名称</div>
          <Input
            value={state.company_name}
            onChange={(e) => setState({ ...state, company_name: e })}
            placeholder="请输入公司名称"
          />
        </div>
        <div className="form-item">
          <div className="title">所属区域</div>
          <div className="area-type">
            <div className="name">区域授权级别</div>
            <Radio.Group
              defaultValue={state.grade}
              onChange={(e) => changeGrade(e)}
            >
              {/* <Radio
                style={{
                  "--font-size": "14px",
                }}
                value={3}
              >
                省级
              </Radio> */}
              <Radio
                style={{
                  "--font-size": "14px",
                }}
                value={1}
              >
                市级
              </Radio>
              <Radio
                style={{
                  "--font-size": "14px",
                }}
                value={2}
              >
                区级
              </Radio>
            </Radio.Group>
          </div>
          <div className="area" onClick={() => setVisible(true)}>
            {areaVal.length ? (
              <span>{areaVal.join("-")}</span>
            ) : (
              <Input placeholder="请选择所属区域" disabled />
            )}
            <div>
              <RightOutline fontSize={15} color="#333" />
              <Cascader
                options={area}
                visible={visible}
                onCancel={() => setVisible(false)}
                value={areaVal}
                onConfirm={(value) => confirm(value)}
                onSelect={(value) => {
                  if (value.length == 1) {
                    fetchOptionsForValue(value[0], 3);
                  }
                  if (value.length == 2) {
                    fetchOptionsForValue(value[1], 1);
                  }
                  if (value.length == 3) {
                    fetchOptionsForValue(value[2], 2);
                  }
                }}
              ></Cascader>
            </div>
          </div>
        </div>
        <div className="form-item">
          <div className="title">佣金比例（%）</div>
          <Input
            value={state.commission_ratio}
            onChange={(e) => setState({ ...state, commission_ratio: e })}
            placeholder="请输入佣金比例"
          />
        </div>
        <div className="form-item">
          <div className="title">添加免费二维码数量</div>
          <Input
            value={state.total_qrcode}
            onChange={(e) => setState({ ...state, total_qrcode: e })}
            placeholder="请输入数量"
          />
        </div>
        <div className="form-item">
          <div className="title">上传营业执照</div>
          <div className="business_license">
            {state.business_license ? (
              <>
                <img
                  className="business_license_img"
                  src={state.business_license}
                />
                <CloseCircleFill
                  className="close"
                  fontSize={18}
                  color="#3b6cf5"
                  onClick={() => setState({ ...state, business_license: "" })}
                />
              </>
            ) : (
              <div className="add">
                <input
                  ref={business_license_ref}
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={() => upFile()}
                />
                <Image src={require("../../image/add.png")} />
              </div>
            )}
          </div>
        </div>
        <div className="btn">
          <Button block color="primary" size="large" onClick={() => submit()}>
            确认
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddServiceProvider;
