/*
 * @Author: lsw lsw_0524@163.com
 * @Date: 2023-11-20 17:58:15
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-03-18 16:18:52
 * @FilePath: \yiyun_projectd:\webWork\ReactApplication\microcode-benefits-service-provider\src\views\chooseAddType\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import { Image } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import "./index.scss";
const imgUrl = "https://digitalize.tongli3d.com/Upload/website/";

function ChooseAddType() {
  const navigate = useNavigate();
  const [typeList, setTypeList] = useState([
    {
      status: 1,
      title: "邀请代理商",
      desc: "Invite Agents",
      icon: imgUrl + "ia_icon.png",
      bgImg: `url(${imgUrl}ia_bg.png)`,
    },
    // {
    //   status: 0,
    //   title: "邀请合伙人",
    //   desc: "Invite Partners",
    //   bgImg: `url(${imgUrl}ip_bg.png)`,
    //   icon: imgUrl + "ip_icon.png",
    // },
    // {
    //   status: 2,
    //   title: "活动签到",
    //   desc: "Activity Sign In",
    //   bgImg: `url(${imgUrl}ip_bg.png)`,
    //   icon: imgUrl + "ip_icon.png",
    // },
  ]);

  const createITEM = () => {
    let arr = [];
    const toAdd = (data) => {
      if (data.status == 1) {
        navigate("/addServiceProvider");
      } else if (data.status == 0) {
        navigate("/addHeHuo");
      } else if (data.status == 2) {
        // navigate("/activitySignIn");
      }
    };
    typeList.map((item) => {
      let i = (
        <div
          key={item.status}
          className="listItem"
          style={{ backgroundImage: item.bgImg }}
          onClick={() => toAdd(item)}
        >
          <div className="left">
            <div className="title">{item.title}</div>
            <div className="desc">{item.desc}</div>
          </div>
          <div className="right">
            <Image
              className={item.status == 1 ? "ia" : "ip"}
              src={item.icon}
            ></Image>
          </div>
        </div>
      );
      arr.push(i);
    });
    return <>{arr}</>;
  };
  useEffect(() => {
    document.title = "选择注册身份";
  }, []);
  return (
    <>
      <div className="listBox">{createITEM()}</div>
    </>
  );
}
export default ChooseAddType;
