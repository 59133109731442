import React, { useState, useEffect } from "react";
import axios from "../../util/axios";
import { Image, Modal } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import style from "./index.module.scss";
function ReviewProgress() {
  const navigate = useNavigate();
  const location = useLocation();
  let [info, setInfo] = useState({});
  let getInfo = () => {
    axios
      .post("/Api/JuTwitter/StoreSettled/info", {
        sn: localStorage.getItem("sn"),
        utoken: localStorage.getItem("utoken"),
      })
      .then((res) => {
        if (res.code == 0) {
          if (!res.result.follow) {
            Modal.show({
              bodyStyle: { padding: "20px 0 0 0" },
              content: "为了享受更好的服务，请务必长按识别二维码，关注公众号！",
              closeOnMaskClick: !0,
              showCloseButton: !0,
              image:
                "https://eyunpay-club.oss-cn-guangzhou.aliyuncs.com/yunshangquan/qrcode_for_gh_920c5a03ff5e_1280.jpg",
            });
          }
          setInfo(res.result);
        } else {
          Modal.show({
            bodyStyle: { padding: "20px 0 0 0" },
            content: res.msg,
            closeOnMaskClick: !0,
            showCloseButton: !0,
          });
        }
      });
  };
  let popstate = () => {
    if (location.state == 1) {
      navigate("/AddStore");
    } else {
      navigate("/reviewProgress");
    }
  };
  let reset = () => {
    window.removeEventListener("popstate", popstate, false);
    navigate("/AddStore", { state: info.status });
  };
  useEffect(() => {
    document.title = "审核进度";
    getInfo();
    window.addEventListener("popstate", popstate, false);
  }, []);
  return (
    <div className={style.content_box}>
      <div className={style.content}>
        <div className={style.status}>
          <div className={style.line}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={style.text}>
            <div>
              <span>提交成功</span>
            </div>
            <div>
              <span style={{ color: info.status == 1 ? "#FF5A01" : "" }}>
                正在审核
              </span>
            </div>
            <div>
              <span
                style={{
                  color: info.status == 2 || info.status == 3 ? "#FF5A01" : "",
                }}
              >
                {info.status == 1
                  ? "审核结果"
                  : info.status == 2
                  ? "审核成功"
                  : "审核失败"}
              </span>
            </div>
          </div>
        </div>
        <div className={style.info}>
          <div className={style.infoItem}>
            <div>店铺名称</div>
            <div>{info.merchant_name}</div>
          </div>
          <div className={style.infoItem}>
            <div>商家姓名</div>
            <div>{info.contact_name}</div>
          </div>
          <div className={style.infoItem}>
            <div>商家电话</div>
            <div>{info.store_phone}</div>
          </div>
          <div className={style.infoItem}>
            <div>店铺地址</div>
            <div>{info.store_site}</div>
          </div>
          <div className={style.infoItem}>
            <div>所属区域</div>
            <div>
              {info.province_name}
              {info.city_name}
              {info.area_name}
            </div>
          </div>
          <div className={style.infoItem}>
            <div>店铺logo</div>
            <Image src={info.store_logo} />
          </div>
          <div className={style.infoItem}>
            <div>经营资质</div>
            <Image src={info.business_license} fit="cover" />
          </div>
        </div>
        {info.status == 3 ? (
          <>
            <div className={style.remark}>驳回原因：{info.remark}</div>
            <div className={style.reset} onClick={() => reset()}>
              重新填写
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ReviewProgress;
